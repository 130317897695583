import * as React from 'react'
import { SVGProps } from 'react'

const SvgApr = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 11 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m10.153.711.604.604v7.291H9.55V2.773l-7.938 7.938-.854-.854 7.938-7.938H2.862V.711h7.291Z"
      fill={props.color}
    />
  </svg>
)

export default SvgApr
