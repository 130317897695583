import * as React from 'react'
import { SVGProps } from 'react'

const SvgDollar = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 7 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M3.867 6.013c-1.514-.394-2-.8-2-1.434 0-.726.673-1.233 1.8-1.233 1.186 0 1.626.567 1.666 1.4h1.474c-.047-1.147-.747-2.2-2.14-2.54V.746h-2v1.44C1.373 2.466.333 3.306.333 4.593c0 1.54 1.274 2.306 3.134 2.753 1.666.4 2 .987 2 1.607 0 .46-.327 1.193-1.8 1.193-1.374 0-1.914-.613-1.987-1.4H.213c.08 1.46 1.174 2.28 2.454 2.553v1.447h2v-1.433c1.3-.247 2.333-1 2.333-2.367 0-1.893-1.62-2.54-3.133-2.933Z"
      fill={props.color}
    />
  </svg>
)

export default SvgDollar
