import * as React from 'react'
import { SVGProps } from 'react'

const SvgAirdrop = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height="1em"
    viewBox="0 0 10 10"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M8.536 1.211 1.465 8.282m7.07-7.07H3m5.536 0v5.535"
      stroke="currentColor"
    />
  </svg>
)

export default SvgAirdrop
