import * as React from 'react'
import { SVGProps } from 'react'

const SvgStaked = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 10 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.35 3.926 5 6.924l3.65-2.998-3.65-3-3.65 3ZM.348 3.582 4.709 0h.582l4.36 3.582v.687l-4.36 3.582h-.582L.35 4.27v-.687Z"
      fill={props.color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M.582 5.444 5 9.074l4.418-3.63.582.688L5.29 10h-.58L0 6.132l.582-.688Z"
      fill={props.color}
    />
  </svg>
)

export default SvgStaked
