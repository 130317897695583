import * as React from 'react'
import { SVGProps } from 'react'

const SvgGovernance = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height="1em"
    viewBox="0 0 13 14"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M3.333 6.414H2v4.666h1.333V6.414Zm4 0H6v4.666h1.333V6.414Zm5.667 6H.333v1.333H13v-1.333Zm-1.667-6H10v4.666h1.333V6.414ZM6.667 1.92l3.473 1.827H3.193L6.667 1.92Zm0-1.506L.333 3.747V5.08H13V3.747L6.667.414Z"
      fill="currentColor"
    />
  </svg>
)

export default SvgGovernance
