import { useRecoilValue, constSelector } from 'recoil'

import { proposalModulesSelector } from '@dao-dao/state/recoil/selectors/clients/cw-core'
import { reverseProposalsSelector } from '@dao-dao/state/recoil/selectors/clients/cw-proposal-single'

import { ProposalItem } from './ProposalItem'
import { DAO_ADDRESS } from '@/util'

export const ProposalsContent = () => {
  const proposalModuleAddress = useRecoilValue(
    proposalModulesSelector({ contractAddress: DAO_ADDRESS, params: [{}] })
  )?.[0]

  const allProposalResponses = useRecoilValue(
    proposalModuleAddress
      ? reverseProposalsSelector({
          contractAddress: proposalModuleAddress,
          params: [{ limit: 10 }],
        })
      : constSelector(undefined)
  )?.proposals

  // Comment out oldModuleResponses to improve performance
  // const oldModuleResponses = useRecoilValue(
  //   OLD_PROPOSALS_ADDRESS
  //     ? listProposalsSelector({
  //         contractAddress: OLD_PROPOSALS_ADDRESS,
  //         params: [{}],
  //       })
  //     : constSelector(undefined)
  // )?.proposals

  return (
    <div>
      {/* Only display open/none open if there are proposals. If there are
       * no proposals, the user will still see the 'No history' messsage at
       * the bottom of the page.
       */}
      {allProposalResponses && allProposalResponses.length > 0 && (
        <div className="mb-8 space-y-1">
          {allProposalResponses.map((response) => (
            <ProposalItem key={response.id} proposalResponse={response} />
          ))}
        </div>
      )}

      {/* Comment out oldModuleResponses to improve performance */}
      {/* oldModuleResponses && (
        <>
          <h2 className="flex gap-2 items-center mt-8 caption-text">
            <ChevronDownIcon className="w-4 h-4" /> Previous proposal module
          </h2>
          <div className="mt-4 space-y-1">
            {oldModuleResponses.map((response) => (
              <ProposalItem key={response.id} old proposalResponse={response} />
            ))}
          </div>
        </>
      ) */}
    </div>
  )
}
