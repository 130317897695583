import * as React from 'react'
import { SVGProps } from 'react'

const SvgPie = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height="1em"
    viewBox="0 0 14 15"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M7 7.747v-7.5c2.071 0 3.946.84 5.303 2.197L7 7.747Zm5.375 0a5.35 5.35 0 0 0-1.047-3.187l.903-.878A6.625 6.625 0 1 1 6.25 1.164v1.26a5.376 5.376 0 1 0 6.125 5.323Z"
      fill="currentColor"
    />
  </svg>
)

export default SvgPie
