import * as React from 'react'
import { SVGProps } from 'react'

const SvgHash = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height="1em"
    viewBox="0 0 12 12"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M9.735.257 9.613.87l-.45 2.252h2.712v1.25H8.912l-.55 2.75h3.013v1.25H8.112l-.5 2.498-.122.613-1.225-.246.122-.613.45-2.252H4.113l-.5 2.498-.122.613-1.225-.246.122-.613.45-2.252H.126v-1.25h2.963l.55-2.75H.625v-1.25h3.263l.5-2.498.122-.612 1.225.245-.122.613-.45 2.252h2.725l.5-2.498.122-.612 1.225.245ZM4.912 4.372l-.55 2.75h2.726l.55-2.75H4.912Z"
      fill="currentColor"
    />
  </svg>
)

export default SvgHash
